<script
  lang="ts"
  setup
  name="BasePaginationTable"
  generic="T extends IDataTableBaseRow"
>
import { computed } from 'vue';

import { useOnResize } from '~/features/useOnResize';

import BaseAutoPagination from '~/components/BaseAutoPagination.vue';
import BaseTable from '~/components/BaseTable.vue';
import BaseTableCollapsible from '~/components/BaseTableCollapsible.vue';
import DataTable from '~/components/DataTable.vue';

import type {
  IDataTableBaseRow,
  IDataTableSettings,
  ITableHeader,
  ITableRow,
} from '~/types';

const { isResponsive } = defineProps<{
  headers: ITableHeader[];
  tableSettings?: IDataTableSettings<T>;
  id?: string;
  loading?: boolean;
  tableRows?: ITableRow[];
  clickableRows?: boolean;
  isResponsive?: boolean;
  visibleKeys?: string[];
}>();

const emit = defineEmits<{
  sort: [sortBy: string, direction: string];
}>();

const { isOnMobile } = useOnResize();

const showMobileTable = computed(() => isOnMobile.value && isResponsive);
</script>

<template>
  <div>
    <BaseTableCollapsible
      v-if="showMobileTable"
      :id="id"
      :headers="headers"
      :rows="tableRows"
      :visibleKeys="visibleKeys"
    />

    <BaseTable
      v-else-if="!tableSettings && !showMobileTable"
      :id="id"
      :loading="loading"
      :headers="headers"
      :rows="tableRows"
      :clickable-rows="clickableRows"
      @sort="(key, direction) => emit('sort', key, direction)"
    />

    <DataTable v-else :data="tableRows" :settings="tableSettings" />

    <BaseAutoPagination v-if="!tableSettings" :disabled="loading" styled />
  </div>
</template>
