<script setup lang="ts" name="ModalConfirmUserDelete">
import { computed } from 'vue';

import ModalConfirm from '~/components/ModalConfirm.vue';

import type { PromiseResolvePayload } from '~/types';

const { email, isExternal, hasOneOrganisation, closeModal, isPublic } =
  defineProps<{
    email: string;
    isExternal: boolean;
    isPublic: boolean;
    hasOneOrganisation: boolean;
    closeModal: (
      data: PromiseResolvePayload<'CLOSE'> | PromiseResolvePayload<'CONFIRM'>,
    ) => void;
  }>();

const title = computed(() => {
  if (isPublic) {
    return 'Remove User from Public MyG2?';
  }

  return isExternal || !hasOneOrganisation
    ? 'Remove User from this Organisation?'
    : 'Remove User from MyG2?';
});

const postFix = computed(() => {
  if (isPublic) {
    return 'will be removed from public MyG2 permanently. Are you sure you want to remove it?';
  }

  return isExternal || !hasOneOrganisation
    ? 'will be removed from this organisation. Are you sure you want to remove the user?'
    : 'will be removed from MyG2 permanently. Are you sure you want to remove it?';
});

const message = computed(
  () => `The user <span class="font-bold">${email}</span> ${postFix.value}`,
);

const onClose = () => {
  closeModal({ action: 'CLOSE' });
};

const onConfirm = () => {
  closeModal({ action: 'CONFIRM' });
};
</script>

<template>
  <ModalConfirm
    :title="title"
    :message="message"
    cancel="Cancel"
    icon="ic:baseline-delete"
    modal-type="danger"
    @cancel="onClose"
    @confirm="onConfirm"
  />
</template>
