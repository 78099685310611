<script setup lang="ts" name="DataTableRowCell">
import { computed, ref } from 'vue';

import InfoTooltip from '~/components/InfoTooltip.vue';

import type { DataTableErrorLevel, IDataTableCell } from '~/types';

const {
  selected = false,
  cell,
  selectedCellAddress,
} = defineProps<{
  cell: IDataTableCell;
  highlight?: boolean;
  areaOverflows: boolean;
  selectedCellAddress: string;
  rightOffset: number;
  editable?: boolean;
  selected?: boolean;
  cancelled?: boolean;
  isFirstSticky?: boolean;
  rowExpanded?: boolean;
}>();

const emit = defineEmits<{
  onClick: [cell: IDataTableCell];
  onDoubleClick: [cell: IDataTableCell];
}>();

const fieldRef = ref<HTMLElement>();

const isSticky = computed(() => cell.position === 'sticky');

const errorLevelIs = computed(
  () => (level: DataTableErrorLevel) => cell.error?.level === level,
);

const isCellSelected = computed(() => cell.address === selectedCellAddress);

const cellError = computed(() => cell.error);

const cellTooltip = computed(() => cell.disabled && cell.disabled.tooltip);
</script>

<template>
  <div
    class="w-auto p-4 text-body-1 outline-none"
    tabindex="0"
    role="gridcell"
    ref="fieldRef"
    v-tooltip.top.click="cellTooltip"
    :id="cell.address"
    :class="{
      'text-charcoal-6': cell.disabled && cell.editable,
      cancelled: cancelled,
      'line-through': cancelled && cell.strikeThrough === undefined,

      'sticky top-0': isSticky,
      'group-hover:bg-charcoal-1': isSticky && !cellError,
      'bg-white': isSticky && !selected && !cellError,
      'border-l border-l-charcoal-2': isFirstSticky,
      'cargo-booking-sticky-shadow': isFirstSticky && areaOverflows,

      'bg-warning-1': errorLevelIs('warning'),
      'bg-danger-0': errorLevelIs('error'),
      'flex gap-x-2': cellError,

      'border border-ocean-4': isCellSelected,
      'border border-transparent': !isCellSelected,
      'border-2': cell?.edit,
      'transition duration-300 ease-in-out': cell.highlight || highlight,
      '!bg-ocean-0': selected || highlight || cell.highlight,

      'text-orange-7': cell.changed,
      'text-ocean-8': !cell.changed && rowExpanded,

      'text-left': cell.align === 'left',
      'text-right': cell.align === 'right',
    }"
    :style="{
      right: isSticky ? `${rightOffset}px` : undefined,
      width: cell.edit ? `${fieldRef?.offsetWidth}px` : undefined,
    }"
    :aria-selected="isCellSelected ? 'true' : 'false'"
    @click="emit('onClick', cell)"
    @dblclick="emit('onDoubleClick', cell)"
  >
    <InfoTooltip
      v-if="cellError?.subtitle"
      preventFocus
      :width="250"
      :description="cellError.subtitle"
      :color="errorLevelIs('error') ? 'danger' : 'warning'"
      @onClick="emit('onClick', cell)"
      @onDoubleClick="emit('onDoubleClick', cell)"
    />

    <slot v-bind="{ fieldRef }" />
  </div>
</template>

<style>
.cancelled,
.cancelled input {
  color: theme('colors.charcoal.6');
}

.cancelled input {
  position: relative;
  text-decoration-line: line-through;
}

.cancelled input::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: theme('colors.charcoal.6');
  pointer-events: none;
}
</style>
