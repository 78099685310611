<script lang="ts" setup name="FieldAdminOrganisations">
import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';

import { ROLES } from '~/constants';

import BaseAlert from '~/components/BaseAlert.vue';
import BaseButton from '~/components/BaseButton.vue';
import BaseCardNoResults from '~/components/BaseCardNoResults.vue';
import BaseCheckboxToggle from '~/components/BaseCheckboxToggle.vue';
import FieldTags from '~/components/FieldTags.vue';
import ListCheckbox from '~/components/ListCheckbox.vue';
import SearchField from '~/components/SearchField.vue';

import type { IFormFieldAdminOrganisations, IOptionItem } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue: IOptionItem[];
    field: IFormFieldAdminOrganisations;
    errors?: string[];
  }>(),
  {
    errors: () => [],
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: IOptionItem[]): void;
}>();

const selectedOrganisations = useVModel(props, 'modelValue', emit);
const search = ref('');

const isG2OAdmin = computed(() => props.field.role?.name === ROLES.G2O_ADMIN);

const organisationList = computed(() => {
  if (!search.value) return props.field.organisations;

  return props.field.organisations.filter((organisation) => {
    return organisation.name.toLowerCase().includes(search.value.toLowerCase());
  });
});

const onRemove = (organisation) => {
  if (isG2OAdmin.value) return;

  selectedOrganisations.value = selectedOrganisations.value.filter(
    (item) => item.id !== organisation.id,
  );
};

const toggleAll = () => {
  if (selectedOrganisations.value.length === props.field.organisations.length) {
    selectedOrganisations.value = [];
    return;
  }

  selectedOrganisations.value = [...props.field.organisations];
};

const clearAll = () => {
  if (isG2OAdmin.value) return;

  selectedOrganisations.value = [];
};

const tooltip = computed(() =>
  isG2OAdmin.value
    ? 'All organisations are assigned to G2O Admin Users. In order to unselect please change the role in the previous step'
    : '',
);

const onClearSearch = () => {
  search.value = '';
};
</script>

<template>
  <div>
    <BaseAlert v-if="errors.length > 0" type="error" class="mb-4">
      Please select at least one Organisation in order to proceed.
    </BaseAlert>
    <p class="text-subtitle-1 text-charcoal-9">Assign Organisations</p>

    <p class="mb-4 mt-2">
      User will have access to the assigned organisation(s). Please select at
      least one Organisation in order to proceed.
    </p>
    <FieldTags
      v-if="selectedOrganisations.length"
      class="my-4"
      :items="selectedOrganisations"
      @remove="onRemove"
    />
    <SearchField v-model="search" placeholder="Search by Organisation Name" />
    <BaseCardNoResults
      v-if="organisationList.length === 0"
      message="Please amend the search criteria or clear search field to see all available items."
      @clear="onClearSearch"
    />
    <template v-else>
      <div class="my-4 flex space-x-4">
        <BaseCheckboxToggle
          v-tooltip:checkbox-control.bottom="tooltip"
          :items="field.organisations"
          :selectedItems="selectedOrganisations"
          :disabled="isG2OAdmin"
          @toggle="toggleAll"
        />
        <BaseButton
          v-if="!isG2OAdmin && selectedOrganisations.length > 0"
          size="btn-sm"
          inverted
          variant="btn-danger"
          @click="clearAll"
        >
          clear all
        </BaseButton>
      </div>
      <div
        class="-mx-6 -mb-6 max-h-80 overflow-y-auto overscroll-contain border-b border-charcoal-2"
      >
        <ListCheckbox
          v-for="organisation in organisationList"
          :key="organisation.id"
          v-model="selectedOrganisations"
          :item="organisation"
          :disabled="isG2OAdmin"
          :tooltip="tooltip"
        >
          <span class="flex-1 truncate px-2">
            {{ organisation.name }}
          </span>
        </ListCheckbox>
      </div>
    </template>
  </div>
</template>
