<script setup lang="ts" name="OrganisationListActions">
import { computed } from 'vue';

import { getOrgRoute } from '~/features/useRouteHelpers';

import BaseButton from '~/components/BaseButton.vue';

const props = defineProps<{
  id: string;
  name: string;
  canEdit: boolean;
  canView: boolean;
  disabledTooltip: string;
}>();

const organisationViewPath = computed(() =>
  getOrgRoute('OrganisationSettingsIndex', props.id),
);

const organisationEditPath = computed(() => ({
  name: 'OrganisationSettingsEdit',
  params: {
    organisationId: props.id,
    name: props.name,
    view: 'list',
  },
}));

const getEditTooltip = () => {
  if (!props.canEdit && !props.canView) {
    return props.disabledTooltip;
  }

  return undefined;
};
</script>

<template>
  <div class="flex items-center p-4">
    <BaseButton
      class="!pl-0"
      inverted
      icon="ic:baseline-edit"
      size="btn-sm"
      :disabled="!canEdit"
      :to="organisationEditPath"
      :tooltip-description="getEditTooltip()"
    >
      Edit
    </BaseButton>
    <BaseButton
      inverted
      icon="mdi:eye"
      size="btn-sm"
      :to="organisationViewPath"
      :disabled="!canView"
      :tooltip-description="canView ? undefined : disabledTooltip"
    >
      View
    </BaseButton>
  </div>
</template>
