<script lang="ts" setup name="BaseTableClickableRow">
import type { Component } from 'vue';

import Icon from '~/components/Icon.vue';

import type { ITableColumnType, ITableHeader, ITableRow } from '~/types';

const { headers, row, getCellComponent } = defineProps<{
  headers: ITableHeader[];
  row: ITableRow;
  getCellComponent: (item?: ITableColumnType) => Component;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();
</script>

<template>
  <tr
    data-test="table-row-clickable"
    class="cursor-pointer hover:bg-charcoal-1"
    @click="emit('click')"
  >
    <td v-for="header in headers" :key="header.key">
      <component
        :is="getCellComponent(header.type)"
        :item="row"
        :header="header"
      />
    </td>

    <td class="py-4 pl-6 pr-4">
      <div class="flex justify-end">
        <Icon icon="mdi:chevron-right" class="text-primary-8" />
      </div>
    </td>
  </tr>
</template>
