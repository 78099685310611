<script lang="ts" setup name="BaseTable">
import type { Component } from 'vue';
import { computed } from 'vue';

import { TABLE_COLUMN_TYPE } from '~/constants';

import BaseTableClickableRow from '~/components/BaseTableClickableRow.vue';
import TableColumnCustom from '~/components/TableColumnCustom.vue';
import TableColumnDefault from '~/components/TableColumnDefault.vue';
import TableColumnLink from '~/components/TableColumnLink.vue';
import TableColumnLoading from '~/components/TableColumnLoading.vue';
import TableColumnNumber from '~/components/TableColumnNumber.vue';
import TableColumnNumberUnit from '~/components/TableColumnNumberUnit.vue';
import TableHeaderCell from '~/components/TableHeaderCell.vue';

import type { ITableColumnType, ITableHeader, ITableRow } from '~/types';

const {
  id = 'Statistics Table',
  headers,
  loading = false,
  rows = [],
  clickableRows = false,
} = defineProps<{
  id?: string;
  headers: ITableHeader[];
  rows?: ITableRow[];
  loading?: boolean;
  clickableRows?: boolean;
}>();

const emit = defineEmits<{
  sort: [sortBy: string, direction: string];
}>();

const LOADING_STATE_COLUMNS = 5;

const COLUMN_TYPE_TO_COMPONENT: Record<ITableColumnType, Component> = {
  number: TableColumnNumber,
  number_unit: TableColumnNumberUnit,
  link: TableColumnLink,
  custom: TableColumnCustom,
};

const RIGHT_ALIGN_COLUMNS: ITableColumnType[] = [
  TABLE_COLUMN_TYPE.NUMBER,
  TABLE_COLUMN_TYPE.NUMBER_UNIT,
];

const getCellComponent = computed(
  () => (type?: ITableColumnType) =>
    type ? COLUMN_TYPE_TO_COMPONENT[type] : TableColumnDefault,
);

const isRightAligned = computed(
  () => (type?: ITableColumnType) =>
    type ? RIGHT_ALIGN_COLUMNS.includes(type) : null,
);

const rowComponent = computed(() => (clickableRows ? 'RouterLink' : 'tr'));
</script>

<template>
  <table :aria-label="id" class="w-full table-auto">
    <thead class="bg-charcoal-2 text-base font-bold text-secondary-9">
      <tr
        class="border-b border-charcoal-2 text-left"
        :class="{
          'animate-pulse rounded-full bg-charcoal-2 text-charcoal-2': loading,
        }"
      >
        <TableHeaderCell
          v-for="header in headers"
          data-testid="table-header"
          :key="header.key"
          :sortable="header.sortable"
          :loading="loading"
          :size="header.size"
          :align-right="isRightAligned(header.type) || header.alignRight"
          @sort="emit('sort', header.key, $event)"
        >
          {{ header.label }}
        </TableHeaderCell>

        <th v-if="clickableRows" id="empty-gap-for-icon" />
      </tr>
    </thead>

    <tbody class="divide-y divide-charcoal-2 text-sm">
      <tr v-if="!rows.length && !loading" class="text-center">
        <td class="h-36 text-charcoal-6" :colspan="headers.length">
          No data available yet
        </td>
      </tr>

      <template v-if="loading">
        <tr
          v-for="i in LOADING_STATE_COLUMNS"
          :key="`loading-row-${i}`"
          class="animate animate-pulse"
        >
          <TableColumnLoading
            v-for="header in headers"
            :key="header.key"
            :is-right-aligned="isRightAligned(header.type) || header.alignRight"
          />
        </tr>
      </template>

      <template v-else>
        <component
          :is="rowComponent"
          v-for="row in rows"
          :key="row.id"
          :to="row.path"
          custom
          v-bind="clickableRows ? {} : { 'data-test': 'table-row' }"
          v-slot="slotProps"
        >
          <BaseTableClickableRow
            v-if="clickableRows"
            :row="row"
            :headers="headers"
            :get-cell-component="getCellComponent"
            @click="slotProps.navigate"
          />

          <template v-else>
            <td v-for="header in headers" :key="header.key">
              <component
                :is="getCellComponent(header.type)"
                :item="row"
                :header="header"
                data-testid="table-cell"
              />
            </td>
          </template>
        </component>
      </template>
    </tbody>
  </table>
</template>
