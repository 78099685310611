import type {
  IDataTableBaseCell,
  IDataTableBaseRow,
  IDataTableSettings,
} from '~/types';

import { getStickyActionsWidth } from './useDataTableStickyColumn';

const getColumnWidth = (column: IDataTableBaseCell) => {
  if (column.width && !column.position) {
    return `${column.width}px`;
  }

  if (column.minWidth && column.maxWidth) {
    return `minmax(${column.minWidth}px, ${column.maxWidth}px)`;
  }

  if (column.minWidth) {
    return `minmax(${column.minWidth}px, 1fr)`;
  }

  if (column.maxWidth) {
    return `minmax(max-content, ${column.maxWidth}px)`;
  }

  return 'minmax(max-content, 1fr)';
};

export const useDataTableGridTemplateColumns = <T extends IDataTableBaseRow>(
  settings: IDataTableSettings<T>,
) => {
  const columnsWidths = settings.columns
    .filter((column) => column.position !== 'sticky')
    .map(getColumnWidth)
    .join(' ');

  const stickyColumnsWidths = settings.columns
    .filter((column) => column.position === 'sticky')
    .map(getColumnWidth)
    .join(' ');

  const firstColumnWidth =
    settings.collapsibleRows || settings.checkboxSelection ? '72px' : '';

  const clickableRowColumnWidth = settings.clickableRows ? '72px' : '';

  const actionsWidth = settings.actions
    ? `${getStickyActionsWidth(settings.actions)}px`
    : '';

  const hasStickyElements = Boolean(stickyColumnsWidths || actionsWidth);

  const columnsEndLine = hasStickyElements
    ? '[columns-end sticky-start]'
    : '[columns-end]';

  const stickyEndLine = hasStickyElements ? '[sticky-end]' : '';

  return {
    gridTemplateColumns: [
      firstColumnWidth,
      '[columns-start]',
      columnsWidths,
      columnsEndLine,
      stickyColumnsWidths,
      actionsWidth,
      stickyEndLine,
      clickableRowColumnWidth,
    ]
      .filter(Boolean)
      .join(' '),
  };
};
