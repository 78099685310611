<script lang="ts" setup name="AdminUserRejectRequest">
import { computed, ref } from 'vue';

import { ORIGIN_EXTERNAL } from '~/constants';

import { useService } from '~/features/useService.js';
import { useState } from '~/features/useState';

import Service from '~/services/Service';

import BaseAlert from '~/components/BaseAlert.vue';
import BaseButton from '~/components/BaseButton.vue';
import BasePage from '~/components/BasePage.vue';
import PendingUserInfo from '~/components/PendingUserInfo.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import SuccessCard from '~/components/SuccessCard.vue';

import type { IAdminRequestedUser } from '~/types';

import AdminUserRequest from '~/pages/AdminUserRequest.vue';

const { userId } = defineProps<{
  userId: string;
}>();
const { stateIs, setState } = useState('IDLE', 'FINISHED', 'ERROR', 'RESOLVED');

const toUserListPath = {
  name: 'AdminUsersIndex',
  query: {
    origin: ORIGIN_EXTERNAL,
  },
};

const user = ref<IAdminRequestedUser>();

const fullName = computed(
  () => `${user.value?.first_name} ${user.value?.last_name}`,
);

const page = computed(() => ({
  title: 'Reject User',
  backButton: {
    label: 'User list',
    to: toUserListPath,
  },
}));

const serviceState = useService(
  Service.users()
    .publicRequests()
    .byId(userId)
    .onErrorConflict(() => {
      setState('RESOLVED');
    }),
  ({ data }: { data: IAdminRequestedUser }) => {
    user.value = data;

    return true;
  },
);

const onDelete = () => {
  Service.users()
    .publicRequests()
    .destroy(userId)
    .onSuccess(async () => {
      setState('FINISHED');
    })
    .onErrorConflict(() => {
      setState('RESOLVED');
    })
    .onError(() => {
      setState('ERROR');
    })
    .execute();
};
</script>

<template>
  <AdminUserRequest v-if="stateIs('RESOLVED')" />
  <BasePage v-else v-bind="page">
    <BaseAlert v-if="stateIs('ERROR')" type="error" class="col-span-full">
      Sorry, we could not reject this user. Please try again.
    </BaseAlert>

    <div v-if="stateIs('FINISHED')" class="col-span-full">
      <SuccessCard title="User Rejected!">
        <template #message>
          <p class="mx-2 my-4 text-center text-primary-8">
            You have rejected
            <span class="font-bold"> {{ fullName }} </span>’s request to get a
            full access MyG2.
          </p>
        </template>

        <BaseButton :to="toUserListPath" variant="btn-secondary">
          go to user list
        </BaseButton>
      </SuccessCard>
    </div>

    <ServiceCard v-else v-bind="serviceState" styled>
      <div class="col-span-full rounded-md bg-white p-6 shadow">
        <p class="text-subtitle-1 text-charcoal-9">
          Reject {{ fullName }} from Full Access MyG2?
        </p>
        <p class="mt-2 text-body-1">
          Are you sure you want to reject full access for this user?
        </p>
        <PendingUserInfo class="pt-6" :user="user!" />
      </div>

      <div
        class="col-span-full flex justify-end gap-x-4 rounded bg-white p-6 shadow"
      >
        <BaseButton inverted :to="toUserListPath"> Cancel </BaseButton>
        <BaseButton @click="onDelete" variant="btn-danger"> Reject </BaseButton>
      </div>
    </ServiceCard>
  </BasePage>
</template>
