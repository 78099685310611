<script lang="ts" setup name="AdminUsersIndex">
import { computed, reactive, ref } from 'vue';

import { ORIGIN_EXTERNAL } from '~/constants';
import { useProfileStore, useSnackbarStore } from '~/store';

import { useService } from '~/features/useService.js';
import { storage } from '~/features/useStorage';
import {
  getIdsFromArray,
  getItemsFromSearch,
  getKeyFromSearch,
} from '~/features/useUrlSearch';

import Service from '~/services/Service.js';

import BaseAutoPagination from '~/components/BaseAutoPagination.vue';
import BaseDivideWrapper from '~/components/BaseDivideWrapper.vue';
import BasePage from '~/components/BasePage.vue';
import CustomCard from '~/components/CustomCard.vue';
import SearchField from '~/components/SearchField.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import UserListFilters from '~/components/UserListFilters.vue';
import UserListUserCard from '~/components/UserListUserCard.vue';
import UsersListPendingUsers from '~/components/UsersListPendingUsers.vue';

import type {
  IAdminUserListPendingUser,
  IAdminUserListQuery,
  IAdminUserListUsers,
  IBasePage,
} from '~/types';

const page = computed<IBasePage>(() => ({
  title: 'User List',
  actions: [
    {
      label: 'New G2O User',
      icon: 'mdi:plus',
      to: { name: 'AdminUserNew' },
    },
  ],
}));

const snackbar = useSnackbarStore();
const profile = computed(() => useProfileStore().profile);
const filters = computed(() => storage.getUserListQuery());

const users = ref<IAdminUserListUsers[]>([]);
const pendingUserRequests = ref<IAdminUserListPendingUser[]>(
  storage.getPendingUserRequests(),
);

const fromEmail = computed(
  () => getKeyFromSearch('origin') === ORIGIN_EXTERNAL,
);

const query = reactive<IAdminUserListQuery>({
  organisations: getItemsFromSearch(
    'organisation',
    filters.value.organisations,
  ),
  roles: getItemsFromSearch('role', filters.value.roles),
  userTypes: getItemsFromSearch('user_type', filters.value.user_types),
  search: getKeyFromSearch('search'),
});

const serviceState = useService(
  Service.users().index(),
  ({ data }) => {
    if (!data.length) return false;

    users.value = data;

    return true;
  },
  {
    cancelOnNewRequest: true,
    paginated: true,
    query,
    getQuery: () => ({
      organisation: getIdsFromArray(query.organisations),
      role: getIdsFromArray(query.roles),
      user_type: getIdsFromArray(query.userTypes),
      search: query.search || '',
    }),
  },
);

const onDeleteUser = (id: string, email: string) => {
  Service.user(id)
    .destroy()
    .onSuccess(() => {
      snackbar.add({
        type: 'success',
        html: `The user <span class="font-bold">${email}</span> removed successfully.`,
      });

      pendingUserRequests.value = pendingUserRequests.value.filter(
        (user) => user.email !== email,
      );

      serviceState.fetchData();
    })
    .execute();
};

const onFiltersClear = () => {
  query.organisations = [];
  query.roles = [];
  query.userTypes = [];
  query.search = '';
};
</script>

<template>
  <BasePage v-bind="page">
    <UsersListPendingUsers
      v-if="pendingUserRequests.length"
      class="col-span-full"
      :open="fromEmail"
      :users="pendingUserRequests"
    />

    <div class="col-span-full">
      <CustomCard>
        <SearchField
          v-if="!serviceState.stateHasErrors"
          v-model="query.search"
          placeholder="Search by Name, Surname or Email"
        />
        <UserListFilters
          v-if="!serviceState.stateHasErrors"
          v-model="query"
          :organisations="filters.organisations"
          :roles="filters.roles"
          :user-types="filters.user_types"
        />
        <ServiceCard
          v-bind="serviceState"
          no-results-message="Please amend the search criteria or filter field to see all users"
          @clear="onFiltersClear"
        >
          <BaseDivideWrapper data-test="user-list-card" with-top-border>
            <UserListUserCard
              v-for="user in users"
              :key="user.email"
              v-bind="user"
              :current-user-id="profile.id"
              @delete-user="onDeleteUser"
            />
          </BaseDivideWrapper>
          <BaseAutoPagination styled />
        </ServiceCard>
      </CustomCard>
    </div>
  </BasePage>
</template>
