<script lang="ts" setup name="TableHeaderSort">
import { computed, ref } from 'vue';

import SortIcon from '~/components/SortIcon.vue';

import type { SortDirection } from '~/types';

defineProps<{
  loading?: boolean;
}>();

const emit = defineEmits<{
  sort: [direction: SortDirection];
}>();

const direction = ref<SortDirection | null>(null);

const sorted = computed(() => direction.value === 'asc');

const toggleDirection = () => {
  direction.value = direction.value === 'asc' ? 'desc' : 'asc';
  emit('sort', direction.value);
};
</script>

<template>
  <button v-if="!loading" type="button" class="size-6" @click="toggleDirection">
    <SortIcon :sorted="sorted" />
  </button>
</template>
