<script lang="ts" setup name="BaseTableCollapsible">
import BaseButton from '~/components/BaseButton.vue';
import BaseItem from '~/components/BaseItem.vue';
import TableHeaderCell from '~/components/TableHeaderCell.vue';
import TableRowCollapsible from '~/components/TableRowCollapsible.vue';

import type { ITableHeader, ITableRow } from '~/types';

const {
  id = 'Statistics Table',
  rows = [],
  headers,
  visibleKeys,
} = defineProps<{
  id?: string;
  headers: ITableHeader[];
  rows?: ITableRow[];
  visibleKeys?: string[];
}>();

const VISIBLE_HEADERS = 2;
const visibleHeaders = visibleKeys
  ? headers.filter(({ key }) => visibleKeys.includes(key))
  : headers.slice(0, VISIBLE_HEADERS);

const collapsibleHeaders = visibleKeys
  ? headers.filter(({ key }) => !visibleKeys.includes(key))
  : headers.slice(VISIBLE_HEADERS);
</script>

<template>
  <table :aria-label="id" class="w-full table-auto">
    <thead class="bg-charcoal-2 text-base font-bold text-secondary-9">
      <tr class="border-b border-charcoal-2 text-left">
        <TableHeaderCell
          v-for="{ label, key, alignRight = false } in visibleHeaders"
          data-testid="table-header"
          :key="key"
          :align-right="alignRight"
        >
          {{ label }}
        </TableHeaderCell>

        <TableHeaderCell />
      </tr>
    </thead>

    <tbody class="divide-y divide-charcoal-2 text-sm text-secondary-9">
      <TableRowCollapsible
        v-for="row in rows"
        :key="row.id"
        :headers="visibleHeaders"
        :row="row"
      >
        <div class="grid grid-cols-2 gap-y-5 py-8 pl-8">
          <BaseItem
            v-for="{ key, label } in collapsibleHeaders"
            :key="key"
            :label="label!"
            :value="row[key] as string"
          />
        </div>

        <BaseButton class="mx-auto mb-6" inverted :to="row.path">
          view details
        </BaseButton>
      </TableRowCollapsible>
    </tbody>
  </table>
</template>
