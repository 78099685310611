<script setup lang="ts" name="ModalConfirmDanger">
import ModalConfirm from '~/components/ModalConfirm.vue';

import type { IButtonVariant, IconType, PromiseResolvePayload } from '~/types';

const {
  title,
  closeModal,
  confirm = 'Confirm',
  cancel = 'Cancel',
  message = '',
  icon = 'ic:baseline-delete',
} = defineProps<{
  title: string;
  confirm?: string;
  cancel?: string;
  message?: string;
  icon?: IconType;
  confirmType?: IButtonVariant;
  closeModal: (
    data: PromiseResolvePayload<'CLOSE'> | PromiseResolvePayload<'CONFIRM'>,
  ) => void;
}>();

const onClose = () => {
  closeModal({ action: 'CLOSE' });
};

const onConfirm = () => {
  closeModal({ action: 'CONFIRM' });
};
</script>

<template>
  <ModalConfirm
    modal-type="danger"
    confirmType="btn-danger"
    :title="title"
    :confirm="confirm"
    :cancel="cancel"
    :message="message"
    :icon="icon"
    @cancel="onClose"
    @confirm="onConfirm"
  />
</template>
