<script setup lang="ts" name="AdminOrganisationsTypeTag">
import { computed } from 'vue';

import { BASE_TAG_CONTRAST, ORGANISATION_TYPE } from '~/constants';

import BaseTag from '~/components/BaseTag.vue';

import type { BaseTagContrastType, IOrganisationType } from '~/types';

const { type } = defineProps<{
  type: IOrganisationType;
}>();

const TYPE_TO_TEXT = {
  [ORGANISATION_TYPE.AGENCY]: 'broker',
};

const TYPE_TO_CONTRAST: Record<IOrganisationType, BaseTagContrastType> = {
  [ORGANISATION_TYPE.CHARTERER]: BASE_TAG_CONTRAST.INDIGO_1,
  [ORGANISATION_TYPE.AGENCY]: BASE_TAG_CONTRAST.AMBER_1,
};

const tagVariant = computed(() => TYPE_TO_CONTRAST[type]);
</script>

<template>
  <div class="p-4">
    <BaseTag :variant="tagVariant" :name="TYPE_TO_TEXT[type] || type" />
  </div>
</template>
