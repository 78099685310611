<script lang="ts" setup name="UserListUserTypeTag">
import { computed } from 'vue';

import { BASE_TAG_CONTRAST } from '~/constants';

import BaseTag from '~/components/BaseTag.vue';

import type { BaseTagContrastType, IProfileType } from '~/types';

const { userType } = defineProps<{
  userType: IProfileType;
}>();

const TYPE_TO_TEXT: Record<IProfileType, string> = {
  g2o: 'G2O',
  customer: 'Customer',
  public: 'Public',
};

const TYPE_TO_CONTRAST: Record<IProfileType, BaseTagContrastType> = {
  g2o: BASE_TAG_CONTRAST.OCEAN_6,
  customer: BASE_TAG_CONTRAST.ORANGE_3,
  public: BASE_TAG_CONTRAST.PURPLE_3,
};

const tagVariant = computed(() => TYPE_TO_CONTRAST[userType]);
const tagName = computed(() => TYPE_TO_TEXT[userType]);
</script>

<template>
  <div class="flex flex-col justify-evenly">
    <p class="text-sm text-charcoal-6">User Type</p>
    <BaseTag :variant="tagVariant" :name="tagName" />
  </div>
</template>
