import type {
  RouteLocationNamedRaw,
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router';

import { useOrganisationsStore, useProfileStore } from '~/store';

export const isSamePath = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) => to.fullPath.split('?')[0] === from.fullPath.split('?')[0];

export const hasQuery = (route: RouteLocationNormalized) =>
  Object.keys(route.query).length > 0;

export const getOrgRoute = (
  name: string,
  organisationId: OrganisationId,
): RouteLocationNamedRaw => ({
  name,
  params: {
    organisationId,
  },
});

export const getHomeRouteBasedOnUser = (): RouteLocationRaw => {
  const profileStore = useProfileStore();
  const organisationStore = useOrganisationsStore();
  const organisationId = organisationStore.defaultOrganisationId;
  if (!profileStore.isAuthenticated) {
    return { name: 'AuthLogin' };
  }

  if (profileStore.userType === 'g2o') {
    return { name: 'AdminOrganisationsIndex' };
  }

  if (profileStore.userType === 'public') {
    return { name: 'PublicSailingScheduleIndex' };
  }

  if (organisationId) {
    return getOrgRoute('OrganisationHomeIndex', organisationId);
  }

  return { name: 'AuthLogin' };
};
