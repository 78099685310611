<script lang="ts" setup name="TableColumnNumberUnit">
import { computed } from 'vue';

import { formatNumber } from '~/features/useNumbers';

import type { ITableHeader, ITableRow } from '~/types';

const { item, header } = defineProps<{
  item: ITableRow;
  header: ITableHeader;
}>();

const value = computed(() => {
  const number = item[header.key] as string;
  return formatNumber(number, header.decimal || 0);
});

const unit = computed(() => header.unit);
</script>

<template>
  <div class="p-4 text-right text-body-1">
    <p>
      {{ value }}
      <span v-if="unit" class="text-charcoal-6">{{ unit }}</span>
    </p>
  </div>
</template>
