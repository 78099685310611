export * from './alert';
export * from './analytics';
export * from './avatar';
export * from './baseItem';
export * from './button';
export * from './cargoBooking';
export * from './colors';
export * from './events';
export * from './features';
export * from './icons';
export * from './kpi';
export * from './leaflet';
export * from './localStorageKeys';
export * from './notifications';
export * from './organisationHome';
export * from './organisationNominations';
export * from './organisationStatistics';
export * from './pageNames';
export * from './permissions';
export * from './registration';
export * from './snackbar';
export * from './states';
export * from './stores';
export * from './table';
export * from './tags';
export * from './tradeLanes';
export * from './userNotificationManagement';
export * from './users';

export const SORT_ORDER = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
} as const;

export const PORT_TYPES = {
  LOADING: 'Load',
  DISCHARGE: 'Discharge',
  FINAL: 'Final',
} as const;

export const MILESTONE_STATUS = {
  UPCOMING: 'UPCOMING',
  UPCOMING_ACTIVE: 'UPCOMING_ACTIVE',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_PROGRESS_ACTIVE: 'IN_PROGRESS_ACTIVE',
  DONE: 'DONE',
} as const;

export const STATUS_TO_MILESTONE_STATUS = {
  est_arrival: MILESTONE_STATUS.UPCOMING,
  arrived: MILESTONE_STATUS.IN_PROGRESS,
  departed: MILESTONE_STATUS.DONE,
} as const;

export const STATISTICS_PERIOD_MIN_DATE = '2017/05/01';

export const SHIPMENTS_STATUS = {
  ONGOING: 'ongoing',
  UPCOMING: 'upcoming',
  COMPLETED: 'completed',
} as const;

export const SHIPMENT_TABS = {
  UPCOMING: 'upcoming',
  ONGOING: 'ongoing',
  COMPLETED: 'completed',
  ALL: 'all',
} as const;

export const PORTS = {
  L: 'L',
  D: 'D',
} as const;

export const PORT_TO_TEXT = {
  [PORTS.L]: 'Load',
  [PORTS.D]: 'Discharge',
} as const;

export const ORIGIN_EXTERNAL = 'external';

export const SCREENS = {
  xxs: '350px',
  xs: '400px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1281px',
  '2xl': '1536px',
  '4xl': '1720px',
  desktop: '1920px',
} as const;

export const GRID_SPACING = {
  '03': '8px',
  '05': '16px',
  '06': '24px',
  '08': '40px',
  '09': '48px',
  10: '56px',
  12: '80px',
  13: '96px',
} as const;

export const NAVIGATION_SIZE = {
  sidebar: '295px',
  'sidebar-closed': '75px',
  header: '72px',
} as const;

export const ORGANISATION_TYPE = {
  CHARTERER: 'charterer',
  AGENCY: 'agency',
} as const;
