<script setup lang="ts" name="BaseCircle">
import { computed } from 'vue';

import Icon from '~/components/Icon.vue';

import type {
  BaseCircleBackground,
  BaseCircleBorder,
  BaseCircleColor,
  BaseCircleSize,
  IconType,
} from '~/types';

const props = defineProps<{
  number?: number;
  icon?: IconType | null;
  disabled?: boolean;
  hoverable?: boolean;
  size: BaseCircleSize;
  border?: BaseCircleBorder;
  color?: BaseCircleColor;
  background: BaseCircleBackground;
  transition?: boolean;
}>();

const md = computed(() => props.size === 'md');
const sm = computed(() => props.size === 'sm');
const xs = computed(() => props.size === 'xs');

const primary = computed(() => props.color === 'primary');
const secondary = computed(() => props.color === 'secondary');

const solid = computed(() => props.border === 'solid');
const dashed = computed(() => props.border === 'dashed');

const filled = computed(() => props.background === 'filled');
const transparent = computed(() => props.background === 'transparent');
const pulse = computed(() => props.background === 'pulse');
</script>

<template>
  <div
    class="flex-center shrink-0 rounded-full"
    :class="{
      'size-6': md,
      'size-5': sm,
      'size-3': xs,

      'transition duration-1000 ease-in-out': transition,

      'border border-charcoal-5 text-charcoal-5':
        disabled && transparent && solid,
      'border border-dashed border-charcoal-5 text-charcoal-5':
        disabled && transparent && dashed,
      'border border-primary-8 text-primary-8':
        primary && transparent && solid && !disabled,
      'border border-dashed border-primary-8 text-primary-8':
        primary && transparent && dashed && !disabled,
      'border border-secondary-3 text-secondary-3':
        secondary && transparent && solid && !disabled,
      'border border-dashed border-secondary-3 text-secondary-3':
        secondary && transparent && dashed && !disabled,

      'bg-charcoal-4 text-white': disabled && filled,
      'bg-primary-8 text-white': primary && filled && !disabled,
      'bg-secondary-3 text-white': secondary && filled && !disabled,

      'border border-primary-1': primary && pulse && solid && !disabled,
      'border border-secondary-1': secondary && pulse && solid && !disabled,
      'border border-charcoal-2': pulse && solid && disabled,

      'group-hover:border-primary-8 group-hover:text-primary-8':
        disabled && hoverable && primary,
    }"
  >
    <div class="flex flex-col items-center">
      <template v-if="pulse">
        <span
          class="relative flex"
          :class="{ 'size-3': md, 'size-2.5': sm, 'size-1.5': xs }"
        >
          <span
            class="absolute inline-flex h-full w-full rounded-full opacity-75"
            :class="{
              'bg-primary-8': primary && !disabled,
              'bg-secondary-3': secondary && !disabled,
              'bg-charcoal-4': disabled,
              'animate-ping': !disabled,
            }"
          ></span>
          <span
            class="relative inline-flex rounded-full"
            :class="{
              'size-3': md,
              'size-2.5': sm,
              'size-1.5': xs,
              'bg-primary-8': primary && !disabled,
              'bg-secondary-3': secondary && !disabled,
              'bg-charcoal-4': disabled,
            }"
          ></span>
        </span>
      </template>

      <template v-else-if="sm || md">
        <template v-if="icon">
          <Icon
            size="custom"
            :icon="icon"
            :class="{ 'text-xl': md, 'text-base': sm }"
          />
        </template>

        <template v-else-if="number !== undefined">
          <div class="text-center text-body-2">
            {{ number > 9 ? '9+' : number }}
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
