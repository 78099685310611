<script setup lang="ts" name="TableHeaderCell">
import TableHeaderSort from '~/components/TableHeaderSort.vue';

import type { TableHeaderSize } from '~/types';

defineProps<{
  loading?: boolean;
  size?: TableHeaderSize;
  sortable?: boolean;
  alignRight?: boolean;
}>();

const emit = defineEmits<{
  sort: [sortBy: string];
}>();

const onSort = (direction: string) => {
  emit('sort', direction);
};
</script>

<template>
  <th
    class="whitespace-nowrap !rounded-none p-4 px-6 text-subtitle-2 sm:px-4"
    data-testid="table-header"
    :class="{
      'text-loading': loading,
      'text-right': alignRight,

      'w-[600px]': size === 'xl',
      'w-52': size === 'large',
      'w-40': size === 'medium',
      'w-28': size === 'small',
    }"
  >
    <template v-if="!sortable">
      <slot />
    </template>

    <template v-else>
      <span class="flex items-center">
        <slot />

        <TableHeaderSort :loading="loading" @sort="onSort" />
      </span>
    </template>
  </th>
</template>
