export const G2O_ICONS = {
  'g2o:vessel': 'g2o:vessel',
  'g2o:tune-rounded': 'g2o:tune-rounded',
  'g2o:application-review': 'g2o:application-review',
  'g2o:open-invoices': 'g2o:open-invoices',
  'g2o:paid-invoices': 'g2o:paid-invoices',
  'g2o:paper-plane': 'g2o:paper-plane',
  'g2o:flag-c': 'g2o:flag-c',
  'g2o:flag-e': 'g2o:flag-e',
  'g2o:flag-h': 'g2o:flag-h',
  'g2o:flag-l': 'g2o:flag-l',
  'g2o:flag-m': 'g2o:flag-m',
  'g2o:flag-o': 'g2o:flag-o',
  'g2o:flag-w': 'g2o:flag-w',
  'g2o:baseline-trending-down': 'g2o:baseline-trending-down',
  'g2o:baseline-trending-flat': 'g2o:baseline-trending-flat',
  'g2o:baseline-trending-up': 'g2o:baseline-trending-up',
  'g2o:file-excel': 'g2o:file-excel',
  'g2o:file-excel-table': 'g2o:file-excel-table',
  'g2o:sort-arrows': 'g2o:sort-arrows',
  'g2o:sort-arrows-asc': 'g2o:sort-arrows-asc',
  'g2o:sort-arrows-desc': 'g2o:sort-arrows-desc',
  'g2o:send': 'g2o:send',
} as const;

export const MATERIAL_DESIGN_ICONS = {
  'mdi:bullseye-arrow': 'mdi:bullseye-arrow',
  'mdi:cog': 'mdi:cog',
  'mdi:account-box': 'mdi:account-box',
  'mdi:file-document-edit': 'mdi:file-document-edit',
  'mdi:sort-reverse-variant': 'mdi:sort-reverse-variant',
  'mdi:sort': 'mdi:sort',
  'mdi:sort-descending': 'mdi:sort-descending',
  'mdi:sort-ascending': 'mdi:sort-ascending',
  'mdi:account-plus': 'mdi:account-plus',
  'mdi:briefcase': 'mdi:briefcase',
  'mdi:account-multiple': 'mdi:account-multiple',
  'mdi:home': 'mdi:home',
  'mdi:calendar-blank': 'mdi:calendar-blank',
  'mdi:calendar-check': 'mdi:calendar-check',
  'mdi:ferry': 'mdi:ferry',
  'mdi:chart-box': 'mdi:chart-box',
  'mdi:account-clock': 'mdi:account-clock',
  'mdi:calendar-blank-outline': 'mdi:calendar-blank-outline',
  'mdi:arrow-left-thin': 'mdi:arrow-left-thin',
  'mdi:arrow-right-thin': 'mdi:arrow-right-thin',
  'mdi:information': 'mdi:information',
  'mdi:triangle-small-up': 'mdi:triangle-small-up',
  'mdi:triangle-small-down': 'mdi:triangle-small-down',
  'mdi:loading': 'mdi:loading',
  'mdi:chevron-right': 'mdi:chevron-right',
  'mdi:chevron-left': 'mdi:chevron-left',
  'mdi:chevron-down': 'mdi:chevron-down',
  'mdi:chevron-up': 'mdi:chevron-up',
  'mdi:magnify': 'mdi:magnify',
  'mdi:close-circle': 'mdi:close-circle',
  'mdi:plus': 'mdi:plus',
  'mdi:eye': 'mdi:eye',
  'mdi:eye-off': 'mdi:eye-off',
  'mdi:bell-outline': 'mdi:bell-outline',
  'mdi:bell': 'mdi:bell',
  'mdi:arrow-expand-all': 'mdi:arrow-expand-all',
  'mdi:lifebuoy': 'mdi:lifebuoy',
  'mdi:map-marker-remove-outline': 'mdi:map-marker-remove-outline',
  'mdi:square-edit-outline': 'mdi:square-edit-outline',
  'mdi:account': 'mdi:account',
  'mdi:map': 'mdi:map',
  'mdi:download': 'mdi:download',
  'mdi:mixer-settings': 'mdi:mixer-settings',
  'mdi:tray-arrow-down': 'mdi:tray-arrow-down',
  'mdi:calendar-tick': 'mdi:calendar-tick',
  'mdi:caret-up': 'mdi:caret-up',
  'mdi:caret-down': 'mdi:caret-down',
} as const;

export const GOOGLE_MATERIAL_ICONS = {
  // checkbox
  'ic:baseline-check-box': 'ic:baseline-check-box',
  'ic:baseline-check-box-outline-blank': 'ic:baseline-check-box-outline-blank',
  'ic:baseline-indeterminate-check-box': 'ic:baseline-indeterminate-check-box',
  'ic:baseline-lock': 'ic:baseline-lock',
  'ic:baseline-more-horiz': 'ic:baseline-more-horiz',
  'ic:outline-message': 'ic:outline-message',
  'ic:round-close': 'ic:round-close',
  'ic:baseline-delete': 'ic:baseline-delete',
  'ic:baseline-check-circle': 'ic:baseline-check-circle',
  'ic:baseline-edit': 'ic:baseline-edit',
  'ic:outline-email': 'ic:outline-email',
  'ic:baseline-email': 'ic:baseline-email',
  'ic:baseline-check': 'ic:baseline-check',
  'ic:baseline-menu': 'ic:baseline-menu',
  'ic:baseline-insert-drive-file': 'ic:baseline-insert-drive-file',
  'ic:baseline-download': 'ic:baseline-download',
  'ic:outline-share': 'ic:outline-share',
  'ic:outline-account-circle': 'ic:outline-account-circle',
  'ic:baseline-arrow-forward': 'ic:baseline-arrow-forward',
  'ic:baseline-arrow-back': 'ic:baseline-arrow-back',
  'ic:baseline-arrow-upward': 'ic:baseline-arrow-upward',
  'ic:baseline-arrow-downward': 'ic:baseline-arrow-downward',
  'ic:round-share-location': 'ic:round-share-location',
  'ic:outline-preview': 'ic:outline-preview',
} as const;

export const GOOGLE_MATERIAL_SYMBOLS = {
  'material-symbols:contract': 'material-symbols:contract',
  'material-symbols:tab-new-right': 'material-symbols:tab-new-right',
  'material-symbols:menu-open-rounded': 'material-symbols:menu-open-rounded',
  'material-symbols:alt-route': 'material-symbols:alt-route',
  'material-symbols:history': 'material-symbols:history',
  'material-symbols:tab-move': 'material-symbols:tab-move',
  'material-symbols:personal-places': 'material-symbols:personal-places',
} as const;

export const ICONS = {
  ...G2O_ICONS,
  ...MATERIAL_DESIGN_ICONS,
  ...GOOGLE_MATERIAL_ICONS,
  ...GOOGLE_MATERIAL_SYMBOLS,
} as const;
