<script lang="ts" setup name="BaseAlert">
import { computed } from 'vue';

import { ALERT_TYPE } from '~/constants';

import BaseButton from '~/components/BaseButton.vue';
import Icon from '~/components/Icon.vue';

import type { AlertType, IconType } from '~/types';

const {
  type = 'success',
  inline,
  title,
  icon = 'mdi:information',
} = defineProps<{
  title?: string;
  type?: AlertType;
  inline?: boolean;
  multiline?: boolean;
  large?: boolean;
  icon?: IconType;
  close?: () => void;
}>();

const typeIs = computed(() => (aType: AlertType) => aType === type);
</script>

<template>
  <div
    data-testid="alert"
    class="items-center space-x-3 rounded p-4 shadow"
    :class="[
      {
        'inline-flex': inline,
        flex: !inline,
        'bg-success-1': typeIs(ALERT_TYPE.SUCCESS),
        'bg-danger-0': typeIs(ALERT_TYPE.ERROR),
        'bg-warning-1': typeIs(ALERT_TYPE.WARNING),
        'bg-info-0': typeIs(ALERT_TYPE.INFO),
      },
    ]"
  >
    <Icon
      :icon="icon"
      :class="{
        'text-success-6': typeIs(ALERT_TYPE.SUCCESS),
        'text-danger-6': typeIs(ALERT_TYPE.ERROR),
        'text-warning-8': typeIs(ALERT_TYPE.WARNING),
        'text-info-4': typeIs(ALERT_TYPE.INFO),
      }"
    />

    <div class="flex w-full items-center justify-between">
      <div
        class="flex w-full"
        :class="{
          'flex-col': multiline,
          'flex-col sm:flex-row sm:items-center': !multiline,
        }"
      >
        <p
          v-if="title"
          class="mr-1 font-bold"
          :class="{
            'text-success-9': typeIs(ALERT_TYPE.SUCCESS),
            'text-red-8': typeIs(ALERT_TYPE.ERROR),
            'text-warning-10': typeIs(ALERT_TYPE.WARNING),
            'text-info-8': typeIs(ALERT_TYPE.INFO),
          }"
        >
          {{ title }}
        </p>

        <span
          class="w-full flex-1"
          :class="{
            'text-success-9': typeIs(ALERT_TYPE.SUCCESS),
            'text-danger-7': typeIs(ALERT_TYPE.ERROR),
            'text-warning-10': typeIs(ALERT_TYPE.WARNING),
            'text-info-8': typeIs(ALERT_TYPE.INFO),
          }"
        >
          <slot />
        </span>
      </div>

      <BaseButton
        v-if="close"
        icon="ic:round-close"
        inverted
        size="none"
        iconSize="md"
        variant="btn-tertiary"
        @click="close"
      />
    </div>
  </div>
</template>
