export const BUTTON_VARIANT = {
  PRIMARY: 'btn-primary',
  SECONDARY: 'btn-secondary',
  TERTIARY: 'btn-tertiary',
  DANGER: 'btn-danger',
  INFO: 'btn-info',
  WHITE: 'btn-white',
  WARNING: 'btn-warning',
} as const;

export const BUTTON_TYPE = {
  BUTTON: 'button',
  SUBMIT: 'submit',
} as const;

export const BUTTON_SIZE = {
  NONE: 'none',
  SMALL: 'btn-sm',
  MEDIUM: 'btn-md',
  LARGE: 'btn-lg',
} as const;

export const BUTTON_ICON_SIZE = {
  XSMALL: 'xs',
  MEDIUM: 'md',
  LARGE: 'lg',
} as const;
