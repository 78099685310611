<script lang="ts" setup name="TableRowCollapsible">
import { ref, watch } from 'vue';

import BaseButton from '~/components/BaseButton.vue';
import TransitionExpand from '~/components/TransitionExpand.vue';

import type { ITableHeader, ITableRow } from '~/types';

const { openByDefault = false, closeOnLeave = false } = defineProps<{
  openByDefault?: boolean;
  closeOnLeave?: boolean;
  headers: ITableHeader[];
  row: ITableRow;
}>();

const emit = defineEmits<{
  (e: 'open'): void;
  (e: 'close'): void;
}>();

const isOpen = ref(openByDefault);

const toggle = () => {
  isOpen.value = !isOpen.value;

  if (isOpen.value) {
    emit('open');
  } else {
    emit('close');
  }
};

watch(
  () => closeOnLeave,
  (value) => {
    if (value && isOpen.value) {
      toggle();
    }
  },
);

watch(
  () => openByDefault,
  (newValue) => {
    isOpen.value = newValue;
  },
);
</script>

<template>
  <tr @click="toggle" class="cursor-pointer">
    <td colspan="3">
      <div>
        <div class="flex w-full items-center">
          <div class="flex w-full justify-between">
            <div
              v-for="{ key, alignRight = false } in headers"
              :key="key"
              class="px-6 py-3.5 text-body-1"
              :class="{
                'text-right': alignRight,
                'text-primary-8': isOpen,
              }"
            >
              {{ row[key as keyof ITableRow] }}
            </div>
          </div>

          <BaseButton
            class="ml-auto w-16 sm:w-20"
            inverted
            :icon="isOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'"
          />
        </div>

        <TransitionExpand>
          <div v-if="isOpen" class="flex flex-col bg-charcoal-1">
            <slot />
          </div>
        </TransitionExpand>
      </div>
    </td>
  </tr>
</template>
