import { type ComputedRef, type Ref, ref, watch } from 'vue';

import {
  checkEditableCell,
  getCellError,
  getDynamicCellValue,
  getNestedKeyValue,
  isDynamicCell,
  isNestedKeyCell,
} from '~/features/useDataTableCellHelpers';
import type { IAddressManager } from '~/features/useDataTableSelectedCell';

import type {
  DataTableGetErrorMessage,
  IDataTableBaseCell,
  IDataTableBaseRow,
  IDataTableCell,
  IDataTableCellValue,
  IDataTableError,
  IDataTableRow,
} from '~/types';

export type IToggleEditMode = ({
  rowNumber,
  colNumber,
}: {
  rowNumber: any;
  colNumber: any;
}) => void;

export const useDataTableData = <T extends IDataTableBaseRow>({
  columns,
  data,
  addressManager,
  getErrorMessage,
}: {
  columns: ComputedRef<IDataTableBaseCell[]>;
  data: Ref<T[]>;
  addressManager: IAddressManager;
  getErrorMessage?: DataTableGetErrorMessage<IDataTableError>;
}) => {
  const tableRows = ref([]) as Ref<IDataTableRow[]>;

  const getDisabledObject = (
    cell: IDataTableBaseCell,
    cellIsEditable: boolean,
  ) => {
    if (cellIsEditable || !cell.disabled) {
      return null;
    }

    const tooltip =
      typeof cell.disabled.tooltip === 'function'
        ? cell.disabled.tooltip()
        : cell.disabled.tooltip;

    return {
      tooltip,
    };
  };

  const transformCell = (
    cell: IDataTableBaseCell,
    item: T,
    rowIndex: number,
    customAddress?: string,
  ): IDataTableCell => {
    let cellValue;
    const cellError = getCellError(item.errors, cell);

    if (isDynamicCell(cell)) {
      cellValue = getDynamicCellValue(cell, item);
    } else if (isNestedKeyCell(cell)) {
      cellValue = getNestedKeyValue(cell, item);
    } else {
      cellValue = cell.transform ? cell.transform(item) : item[cell.key];
    }

    const address = customAddress
      ? addressManager.createCustomAddress(customAddress)
      : addressManager.createAddress(columns.value.indexOf(cell), rowIndex);

    const isCellEditable = checkEditableCell(cell, item.editable);

    return {
      header: cell.header,
      address: address,
      value: cellValue,
      edit: false,
      editable: isCellEditable,
      key: cell.key,
      dynamic: cell.dynamic,
      fallback: cell.fallback ?? '-',
      width: cell.width || 150,
      type: cell.type ?? 'numeric',
      padding: cell.padding,
      position: cell.position,
      render: cell.render,
      align: cell.align,
      highlight: false,
      changed: getCellHasChanged(cell, item),
      options: cell.options ? cell.options(item) : null,
      disabled: getDisabledObject(cell, isCellEditable),
      error: cellError && getErrorMessage ? getErrorMessage(cellError) : null,
      valueFormatter: cell.valueFormatter,
      strikeThrough: cell.strikeThrough,
      link: cell.link?.(item) ?? null,
    };
  };

  const getCellHasChanged = (cell: IDataTableBaseCell, item: T) => {
    if (!item.changes || item.changes.length === 0) return false;

    return (
      item.changes.includes(cell.key) ||
      (cell.dynamic ? item.changes.includes(cell.dynamic) : false)
    );
  };

  const transformRow = (row: T, rowIndex: number): IDataTableRow => {
    return {
      id: row.id ?? '',
      cancelled: !!row.cancelled,
      highlight: !!row.highlight,
      cells: columns.value.map((column) =>
        transformCell(column, row, rowIndex),
      ),
    };
  };

  const getRowByIndex = (rowIndex: number) => {
    return tableRows.value[rowIndex];
  };

  const getCellByIndices = (rowIndex: number, colIndex: number) => {
    const row = getRowByIndex(rowIndex);
    return row?.cells[colIndex];
  };

  const getNumberOfCellsInRow = (rowIndex: number) => {
    return tableRows.value[rowIndex]?.cells.length || 0;
  };

  const getTableLength = () => {
    return tableRows.value.length;
  };

  const updateCellValue = ({
    rowNumber,
    colNumber,
    newValue,
  }: {
    rowNumber: number;
    colNumber: number;
    newValue: IDataTableCellValue;
  }) => {
    const cellToUpdate = getCellByIndices(rowNumber, colNumber);

    if (cellToUpdate) {
      cellToUpdate.value = newValue;
      cellToUpdate.highlight = true;
    }

    setTimeout(() => {
      cellToUpdate.highlight = false;
    }, 1500);

    return cellToUpdate;
  };

  const getCellByRowAndCol = ({ rowNumber, colNumber }) => {
    const cell = getCellByIndices(rowNumber, colNumber);

    return cell;
  };

  const toggleEditMode = ({ rowNumber, colNumber }) => {
    const cell = getCellByIndices(rowNumber, colNumber);
    if (cell.editable) {
      cell.edit = !cell.edit;
    }
  };

  const resetEditMode = ({ rowNumber, colNumber }) => {
    const cell = getCellByIndices(rowNumber, colNumber);

    cell.edit = false;
  };

  watch(
    [data, columns],
    ([newData]) => {
      tableRows.value = newData.map(transformRow);
    },
    {
      deep: true,
      immediate: true,
    },
  );

  return {
    tableRows,
    getTableLength,
    getNumberOfCellsInRow,
    getCellByIndices,
    getRowByIndex,
    toggleEditMode,
    resetEditMode,
    updateCellValue,
    getCellByRowAndCol,
  };
};
