<script lang="ts" setup name="UserListUserCard">
/* eslint-disable vue/prop-name-casing */
import { computed } from 'vue';

import { USER_TYPES } from '~/constants';

import { useShowModal } from '~/features/useModalsProvider';

import BaseButton from '~/components/BaseButton.vue';
import BaseItem from '~/components/BaseItem.vue';
import ModalConfirmUserDelete from '~/components/ModalConfirmUserDelete.vue';
import UserListActiveUserInfo from '~/components/UserListActiveUserInfo.vue';
import UserListPendingUserInfo from '~/components/UserListPendingUserInfo.vue';
import UserListUserRole from '~/components/UserListUserRole.vue';
import UserListUserTypeTag from '~/components/UserListUserTypeTag.vue';

import type { IProfileType } from '~/types';

const {
  currentUserId,
  id,
  first_name,
  last_name,
  organisations,
  role,
  user_type,
  email_verified,
  email = '',
  has_all_organisations,
} = defineProps<{
  currentUserId: number;
  id: number;
  first_name: string;
  last_name: string;
  organisations: string[];
  role: string;
  user_type: IProfileType;
  email?: string;
  email_verified?: boolean;
  has_all_organisations?: boolean;
  business_type: string | null;
}>();

const emit = defineEmits(['deleteUser']);

const showModal = useShowModal();
const isInternal = computed(() => user_type === USER_TYPES.G2O);

const canBeEdited = computed(
  () => isInternal.value || user_type === USER_TYPES.CUSTOMER,
);
const isCurrentUser = computed(() => currentUserId === id);

const onRemoveUser = async () => {
  const result = await showModal(ModalConfirmUserDelete, {
    email: email,
    isExternal: false,
    isPublic: user_type === USER_TYPES.PUBLIC,
    hasOneOrganisation: true,
  });

  if (result.action === 'CONFIRM') {
    emit('deleteUser', id, email);
  }
};

const editPath = computed(() => ({
  name: 'AdminUserEdit',
  params: {
    userId: id,
  },
}));

const viewPath = computed(() =>
  isCurrentUser.value
    ? { name: 'AuthProfile', query: { from: 'user-list' } }
    : {
        name: 'AdminUserView',
        params: {
          userId: id,
          type: user_type,
        },
      },
);

const editTooltipDescription = computed(() =>
  isInternal.value
    ? null
    : 'Please select assigned User’s organisation on the left side panel in order to edit this User.',
);
</script>

<template>
  <div class="grid w-full grid-cols-7 gap-4 p-4">
    <div class="col-span-3 2xl:col-span-2">
      <UserListActiveUserInfo
        v-if="email_verified"
        :first-name="first_name"
        :last-name="last_name"
        :organisations="organisations"
        :is-internal="isInternal"
        :has-all-organisations="has_all_organisations"
      />
      <UserListPendingUserInfo v-else :email="email" />
    </div>

    <UserListUserTypeTag :user-type="user_type" />
    <UserListUserRole :role="role" />
    <BaseItem
      v-if="business_type"
      class="flex flex-col justify-evenly"
      label="Company's Business"
      :value="business_type"
    />
    <div class="col-span-2 col-start-6 flex items-center justify-end">
      <BaseButton
        icon="ic:baseline-delete"
        variant="btn-danger"
        inverted
        class="ml-auto"
        :disabled="isCurrentUser"
        @click="onRemoveUser"
      >
        <span class="hidden xl:block">Remove</span>
      </BaseButton>
      <BaseButton
        v-if="canBeEdited"
        icon="ic:baseline-edit"
        inverted
        :description="editTooltipDescription ? editTooltipDescription : ''"
        :disabled="!isInternal || isCurrentUser"
        :to="editPath"
      >
        <span class="hidden xl:block">Edit</span>
      </BaseButton>
      <BaseButton icon="mdi:eye" inverted :to="viewPath">
        <span class="hidden xl:block">View</span>
      </BaseButton>
    </div>
  </div>
</template>
