import { defineStore } from 'pinia';

import { DEV_FEATURE, PINIA_STORE } from '~/constants';

import type { IDevFeature } from '~/types';

const DEV_FEATURE_TO_ENV = {
  PUBLIC_REGISTER: import.meta.env.VITE_FEATURE_PUBLIC_REGISTER,
  NOTIFICATIONS: import.meta.env.VITE_NOTIFICATIONS_MANAGEMENT,
  KPI: import.meta.env.VITE_FEATURE_KPI,
  STATISTICS_WAITING_FOR_BERTH: import.meta.env
    .VITE_FEATURE_STATISTICS_WAITING_FOR_BERTH,
  SHIPMENTS_NOTIFICATIONS: import.meta.env.VITE_FEATURE_SHIPMENTS_NOTIFICATIONS,
  EMISSIONS_DOWNLOAD: import.meta.env.VITE_FEATURE_EMISSIONS_DOWNLOAD,
  CARGO_NOMINATION: import.meta.env.VITE_CARGO_NOMINATION,
  CARGO_NOMINATION_NOMINATE: import.meta.env.VITE_CARGO_NOMINATION_NOMINATE,
  CARGO_NOMINATION_TRANSFER_TO_VESON: import.meta.env
    .VITE_CARGO_NOMINATION_TRANSFER_TO_VESON,
  CARGO_NOMINATION_BUDGET_OVERVIEW: import.meta.env
    .VITE_CARGO_NOMINATION_BUDGET_OVERVIEW,
} as const;

const getEnv = (key: IDevFeature) => {
  const value = DEV_FEATURE_TO_ENV[key];
  return value?.toString() === 'true';
};

export const useDevFeaturesStore = defineStore(PINIA_STORE.DEV_STORE, {
  state: () => ({
    [DEV_FEATURE.PUBLIC_REGISTER]: getEnv(DEV_FEATURE.PUBLIC_REGISTER),
    [DEV_FEATURE.NOTIFICATIONS]: getEnv(DEV_FEATURE.NOTIFICATIONS),
    [DEV_FEATURE.SHIPMENTS_NOTIFICATIONS]: getEnv(
      DEV_FEATURE.SHIPMENTS_NOTIFICATIONS,
    ),
    [DEV_FEATURE.STATISTICS_WAITING_FOR_BERTH]: getEnv(
      DEV_FEATURE.STATISTICS_WAITING_FOR_BERTH,
    ),
    [DEV_FEATURE.EMISSIONS_DOWNLOAD]: getEnv(DEV_FEATURE.EMISSIONS_DOWNLOAD),
    [DEV_FEATURE.CARGO_NOMINATION]: getEnv(DEV_FEATURE.CARGO_NOMINATION),
    [DEV_FEATURE.CARGO_NOMINATION_NOMINATE]: getEnv(
      DEV_FEATURE.CARGO_NOMINATION_NOMINATE,
    ),
    [DEV_FEATURE.CARGO_NOMINATION_BUDGET_OVERVIEW]: getEnv(
      DEV_FEATURE.CARGO_NOMINATION_BUDGET_OVERVIEW,
    ),
    [DEV_FEATURE.CARGO_NOMINATION_TRANSFER_TO_VESON]: getEnv(
      DEV_FEATURE.CARGO_NOMINATION_TRANSFER_TO_VESON,
    ),
  }),

  getters: {
    enabled: (state) => (feature: IDevFeature) => {
      return state[feature];
    },
  },
});
