<script
  setup
  lang="ts"
  name="DataTableHeader"
  generic="T extends IDataTableBaseRow"
>
import { useVModel } from '@vueuse/core';

import { getStickyColumnOffset } from '~/features/useDataTableStickyColumn';
import { hasStickyColumns } from '~/features/useDataTableStickyColumn';

import BaseCheckboxToggle from '~/components/BaseCheckboxToggle.vue';
import DataTableHeaderCell from '~/components/DataTableHeaderCell.vue';

import type {
  IDataTableBaseCell,
  IDataTableBaseRow,
  IDataTableSettingsActions,
  SortDirection,
} from '~/types';

const props = defineProps<{
  columns: IDataTableBaseCell[];
  areaOverflows: boolean;
  tableData: T[];
  selectedTableRows: T[];
  checkboxSelection?: boolean;
  paddingColumn?: boolean;
  gridWidths?: number[];
  actions?: IDataTableSettingsActions;
}>();

const emit = defineEmits<{
  'update:selectedTableRows': [value: T[]];
  onSort: [column: IDataTableBaseCell, direction: SortDirection];
}>();

const selectedRows = useVModel(props, 'selectedTableRows', emit);

const toggleAll = () => {
  if (props.tableData.length === selectedRows.value.length) {
    selectedRows.value = [];
    return;
  }

  selectedRows.value = [...props.tableData];
};
</script>

<template>
  <div
    class="col-span-full grid grid-cols-subgrid bg-charcoal-2 text-subtitle-2"
  >
    <div v-if="checkboxSelection" class="flex-center px-6 py-4">
      <BaseCheckboxToggle
        hideLabel
        :items="tableData"
        :selectedItems="selectedRows"
        @toggle="toggleAll"
      />
    </div>
    <div v-else-if="paddingColumn"></div>

    <template v-for="column in columns" :key="column.key">
      <DataTableHeaderCell
        :column="column"
        :columns="columns"
        :areaOverflows="areaOverflows"
        :actions="actions"
        :rightOffset="getStickyColumnOffset(column.key, columns, actions)"
        @onSort="(column, direction) => emit('onSort', column, direction)"
      />
    </template>

    <div
      v-if="actions"
      class="cargo-booking-sticky-actions flex items-center bg-charcoal-2 p-4 font-bold"
      :class="{
        'border-l border-l-charcoal-2': !hasStickyColumns(columns),
        'cargo-booking-sticky-shadow':
          areaOverflows && !hasStickyColumns(columns),
      }"
    >
      Actions
    </div>
  </div>
</template>
