<script setup name="FieldTags">
import { computed, ref } from 'vue';

import BaseButton from '~/components/BaseButton.vue';
import FieldTag from '~/components/FieldTag.vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  selectedItemTagKey: {
    type: String,
    default: 'name',
  },
});
const emit = defineEmits(['remove']);
const MAX_ITEMS = 10;
const open = ref(false);
const visibleItems = computed(() =>
  open.value ? props.items : props.items.slice(0, MAX_ITEMS),
);
const hasInvisibleItems = computed(() => props.items.length > MAX_ITEMS);

const onClose = () => {
  open.value = false;
};

const onOpen = () => {
  open.value = true;
};

const onRemoveItem = (item) => {
  emit('remove', item);
};
</script>

<template>
  <div>
    <div class="-m-1 flex max-h-[400px] flex-wrap items-center overflow-y-auto">
      <div v-for="item in visibleItems" :key="item.id" class="m-1">
        <FieldTag
          :name="item[selectedItemTagKey]"
          @click="onRemoveItem(item)"
        />
      </div>
      <template v-if="hasInvisibleItems && !open">
        <p class="m-1 px-3 py-1 text-sm">. . .</p>
        <button
          type="button"
          class="m-1 block rounded-full bg-primary-6 px-3 py-1 text-sm leading-tight text-white hover:bg-ocean-5"
          @click="onOpen"
        >
          +{{ items.length - visibleItems.length }}
        </button>
      </template>
    </div>
    <template v-if="hasInvisibleItems && open">
      <div class="mt-4 flex w-full justify-center">
        <BaseButton inverted @click="onClose"> View Less </BaseButton>
      </div>
    </template>
  </div>
</template>
