<script lang="ts" setup name="TableColumnDefault">
import { computed } from 'vue';

import type { ITableHeader, ITableRow } from '~/types';

const { item, header } = defineProps<{
  item: ITableRow;
  header: ITableHeader;
}>();

const value = computed(() => item[header.key]);
</script>

<template>
  <div
    class="truncate p-4 text-body-1"
    :class="{
      'text-right': header.alignRight,
    }"
  >
    <p v-title="value" class="truncate">{{ value }}</p>
  </div>
</template>
