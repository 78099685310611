<script setup lang="ts" name="TableColumnCustom">
import type { Component } from 'vue';
import { computed } from 'vue';
import { h } from 'vue';

import type { ITableHeader, ITableRow } from '~/types';

const { item, header } = defineProps<{
  item: ITableRow;
  header: ITableHeader;
}>();

type IRenderComponent = (item: ITableRow) => Component;

const renderComponent = computed(() => {
  const componentFunction = item[header.key];

  if (typeof componentFunction === 'function') {
    return componentFunction as IRenderComponent;
  }

  return null;
});

const render = () => {
  const component = renderComponent.value;

  if (typeof component === 'function') {
    const renderedComponent = component(item);
    return renderedComponent ? h(renderedComponent) : null;
  }

  return null;
};
</script>

<template>
  <component :is="render()" v-if="render()" />
</template>
