<script setup lang="ts" name="AdminOrganisationsIndex">
import { computed, h, reactive, ref } from 'vue';

import { hasOneOfPermissions } from '~/features/usePermissions';
import { useService } from '~/features/useService.js';
import { getIdsFromArray, getKeyFromSearch } from '~/features/useUrlSearch';
import { addIf } from '~/features/useUtilities';

import Service from '~/services/Service.js';

import AdminOrganisationsFilters from '~/components/AdminOrganisationsFilters.vue';
import AdminOrganisationsTypeTag from '~/components/AdminOrganisationsTypeTag.vue';
import BasePage from '~/components/BasePage.vue';
import BasePaginationTable from '~/components/BasePaginationTable.vue';
import CustomCard from '~/components/CustomCard.vue';
import OrganisationListActions from '~/components/OrganisationListActions.vue';
import SearchField from '~/components/SearchField.vue';
import ServiceCard from '~/components/ServiceCard.vue';

import type {
  IBasePage,
  IOrganisation,
  IOrganisationType,
  ITableHeader,
  ITableRow,
} from '~/types';

type Organisation = Pick<IOrganisation, 'id' | 'name'> & {
  type: IOrganisationType;
  userCount: number;
  canEdit: boolean;
  canView: boolean;
};

const page = computed<IBasePage>(() => ({
  title: 'Organisation List',
  actions: addIf(hasOneOfPermissions(['organization.create.any']), {
    label: 'New Organisation',
    icon: 'mdi:plus',
    to: { name: 'AdminOrganisationNew' },
  }),
}));

const orgTypes = [
  { id: 'agency', name: 'Broker' },
  { id: 'charterer', name: 'Charterer' },
];

const tableRows = ref<ITableRow[]>([]);

const query = reactive({
  search: getKeyFromSearch('search'),
  sortBy: '',
  sortDirection: '',
});

const filtersQuery = reactive({
  type: [],
});

const onSearchClear = () => {
  query.search = '';
  query.sortBy = '';
  query.sortDirection = '';
  filtersQuery.type = [];
};

const onSort = (key: string, direction: string) => {
  if (query.sortBy === key) {
    query.sortBy = '';
    query.sortDirection = '';
    return;
  }

  query.sortBy = key;
  query.sortDirection = direction;
};

const serviceState = useService(
  Service.organisationList().index(),
  ({ data }: { data: Organisation[] }) => {
    if (!data.length) return false;

    tableRows.value = dataToTableRows(data);
    return true;
  },
  {
    loading: 'skeleton',
    cancelOnNewRequest: true,
    paginated: true,
    query: computed(() => ({
      search: query.search,
      sort_by: query.sortBy,
      sort_direction: query.sortBy ? query.sortDirection : null,
      type: filtersQuery.type,
    })),
    getQuery: () => ({
      search: query.search,
      sort_by: query.sortBy,
      sort_direction: query.sortBy ? query.sortDirection : null,
      type: getIdsFromArray(filtersQuery.type),
    }),
  },
);

const dataToTableRows = (data: Organisation[]) =>
  data.map((org) => ({
    id: org.id,
    name: org.name,
    type: () => h(AdminOrganisationsTypeTag, { type: org.type }),
    count: org.userCount,
    actions: () =>
      h(OrganisationListActions, {
        id: org.id,
        name: org.name,
        canEdit: org.canEdit,
        canView: org.canView,
        disabledTooltip:
          'You are not assigned to this organisation. Please contact a G2O admin for more information.',
      }),
  }));

const headers: ITableHeader[] = [
  { label: 'Organisation Name', key: 'name', size: 'xl' },
  {
    label: 'Organisation Type',
    key: 'type',
    size: 'large',
    sortable: true,
    type: 'custom',
  },
  { label: 'Number of Users', key: 'count', size: 'medium', alignRight: true },
  { label: '', key: '' },
  { label: 'Actions', key: 'actions', type: 'custom', size: 'large' },
];
</script>

<template>
  <BasePage v-bind="page">
    <div class="col-span-full space-y-2">
      <AdminOrganisationsFilters v-model="filtersQuery" :orgTypes="orgTypes">
        <SearchField
          v-if="!serviceState.stateIsError"
          v-model="query.search"
          placeholder="Search by the Organisation Name"
        />
      </AdminOrganisationsFilters>

      <CustomCard>
        <ServiceCard
          v-bind="serviceState"
          @clear="onSearchClear"
          noResultsMessage="Please amend the search criteria or filter options to see available data"
        >
          <BasePaginationTable
            :loading="serviceState.stateIsLoading"
            :headers="headers"
            :tableRows="tableRows"
            @sort="onSort"
          />
        </ServiceCard>
      </CustomCard>
    </div>
  </BasePage>
</template>
