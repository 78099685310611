import type { Ref } from 'vue';
import { nextTick } from 'vue';

import type {
  IDataTableCell,
  IDataTableCellValue,
  IDataTableRow,
} from '~/types';

import type { IToggleEditMode } from './useDataTableData';
import type { IAddressManager } from './useDataTableSelectedCell';

interface IStepCellManagerFocus {
  rowId: string | null;
  key: string;
}

export interface IStepCellManager {
  stepCells: IDataTableCell[];
  resetStepCells: () => void;
  completeCurrentStep: () => void;
  hasUncompletedSteps: () => boolean;
  stepCellValuesSubmitted: () => boolean;
  getCurrentStepCell: () => IDataTableCell | null;
  initializeSteps: (focus: IStepCellManagerFocus[]) => Promise<void>;
  updateCurrentStepValue: (value: IDataTableCellValue) => IDataTableCell | null;
}

export const useDataTableStepCells = (
  tableRows: Ref<IDataTableRow[]>,
  addressManager: IAddressManager,
  toggleEditMode: IToggleEditMode,
): IStepCellManager => {
  const stepCells: IDataTableCell[] = [];
  let currentStepIndex = 0;
  let prevStepCells: IDataTableCell[] = [];

  const resetStepCells = () => {
    stepCells.length = 0;
    currentStepIndex = 0;
  };

  const getCurrentStepCell = () => {
    if (currentStepIndex >= stepCells.length) {
      return null;
    }

    return stepCells[currentStepIndex];
  };

  const initializeSteps = async (focus: IStepCellManagerFocus[]) => {
    await nextTick();

    focus.forEach((step) => {
      const tableRow = tableRows.value.find((row) => row.id === step.rowId);

      const cell = tableRow?.cells.find(
        (cell) => cell.key === step.key || cell.dynamic === step.key,
      );

      if (cell) {
        stepCells.push(cell);
      }
    });

    prevStepCells = JSON.parse(JSON.stringify(stepCells));

    const currentCell = getCurrentStepCell();

    if (!currentCell) {
      throw new Error('Step cells initialized, but no steps were found');
    }

    addressManager.selectAddress(currentCell.address);

    addressManager.focusAndScrollToCell(currentCell.address);

    const parsedAddress = addressManager.parseAddress(currentCell.address);
    toggleEditMode(parsedAddress);
  };

  const updateCurrentStepValue = (
    value: IDataTableCellValue,
  ): IDataTableCell | null => {
    const currentCell = getCurrentStepCell();

    if (!currentCell) {
      return null;
    }

    currentCell.value = value;

    return currentCell;
  };

  const completeCurrentStep = () => {
    const currentCell = getCurrentStepCell();
    currentStepIndex++;
    return currentCell;
  };

  const hasUncompletedSteps = () => currentStepIndex < stepCells.length;

  const extractValues = (cells: IDataTableCell[]) => {
    return cells.map((cell) => cell.value);
  };

  const stepCellValuesSubmitted = () => {
    const prevValues = extractValues(prevStepCells);
    const currentValues = extractValues(stepCells);

    return JSON.stringify(prevValues) !== JSON.stringify(currentValues);
  };

  return {
    stepCells,
    resetStepCells,
    getCurrentStepCell,
    hasUncompletedSteps,
    updateCurrentStepValue,
    completeCurrentStep,
    stepCellValuesSubmitted,
    initializeSteps,
  };
};
