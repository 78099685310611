<script lang="ts" setup name="TableColumnNumber">
import { computed } from 'vue';

import { formatNumber } from '~/features/useNumbers';

import type { ITableHeader, ITableRow } from '~/types';

const { item, header } = defineProps<{
  item: ITableRow;
  header: ITableHeader;
}>();

const value = computed(() => {
  const number = item[header.key] as string;
  return formatNumber(number, header.decimal || 0);
});
</script>

<template>
  <div class="p-4 text-right text-body-1">
    <p>{{ value }}</p>
  </div>
</template>
