import { computed, type ComputedRef, type Ref, ref } from 'vue';
import { h } from 'vue';

import { Item } from '~/features/useBaseItem';

import type {
  IDataTableBaseCell,
  IDataTableBaseRow,
  IDataTableCell,
  IDataTableResponsiveRow,
  IDataTableRow,
  IDataTableSettings,
} from '~/types';

export const useDataTableResponsiveColumns = <T extends IDataTableBaseRow>(
  settings: ComputedRef<IDataTableSettings<T>>,
  tableData: Ref<T[]>,
  tableRows: Ref<IDataTableRow[]>,
) => {
  const collapsibleColumns = ref<IDataTableBaseCell[]>([]);

  const hasVisibleColumns = computed(() =>
    settings.value.columns.some((column) => column.visible),
  );

  const responsiveTableSettings = computed<IDataTableSettings<T>>(() => ({
    ...settings.value,
    collapsibleRows: !!responsiveCollapsibleColumns.value.length,
    columns: responsiveColumns.value,
  }));

  const responsiveColumns = computed(() =>
    settings.value.columns.filter(
      (column) =>
        !responsiveCollapsibleColumns.value.find(
          (collapsibleColumn) => collapsibleColumn.key === column.key,
        ),
    ),
  );

  const getFilteredCells = (
    columns: IDataTableBaseCell[],
    cells: IDataTableCell[],
  ) => {
    const isResponsiveCollapsibleColumn = (cellKey: string) =>
      columns.some((column) => column.key === cellKey);

    return cells.filter((cell) => isResponsiveCollapsibleColumn(cell.key));
  };

  const responsiveTableRows = computed<IDataTableRow[]>(() => {
    if (!tableRows.value.length) return [];

    const columns = responsiveColumns.value;

    const mapRow = (row: IDataTableRow) => ({
      ...row,
      cells: getFilteredCells(columns, row.cells),
    });

    return tableRows.value.map(mapRow);
  });

  const responsiveCollapsibleTableRows = computed<IDataTableResponsiveRow[]>(
    () => {
      if (!tableRows.value.length || !hasVisibleColumns.value) return [];

      const columns = responsiveCollapsibleColumns.value;

      const mapCell = (cell: IDataTableCell, rowIndex: number) => {
        const component = cell.render?.(tableData.value[rowIndex]);
        const cellValue: any = cell.valueFormatter
          ? cell.valueFormatter(cell.value)
          : cell.value;

        return Item.label(cell.header)
          .value(cellValue)
          .component(component && h(component, { value: cell.value }))
          .fallback('-')
          .toBaseItem();
      };

      const mapRow = (row: IDataTableRow, rowIndex: number) => ({
        ...row,
        cells: getFilteredCells(columns, row.cells).map((cell) =>
          mapCell(cell, rowIndex),
        ),
      });

      return tableRows.value.map((row, rowIndex) => mapRow(row, rowIndex));
    },
  );

  const responsiveCollapsibleColumns = computed(() =>
    collapsibleColumns.value.map((column) => {
      const newColumn = settings.value.columns.find(
        (col) => col.key === column.key,
      );

      return { ...column, ...newColumn };
    }),
  );

  const removeCollapsibleColumn = () => {
    collapsibleColumns.value.pop();
  };

  const addCollapsibleColumn = () => {
    const lastColumn = settings.value.columns.length - 1;

    for (let i = lastColumn; i >= 0; i--) {
      const column = { ...settings.value.columns[i] };
      const hasCollapsibleColumn = responsiveCollapsibleColumns.value.find(
        (collapsibleColumn) => collapsibleColumn.key === column.key,
      );

      if (column.visible || hasCollapsibleColumn) {
        continue;
      }

      collapsibleColumns.value.push(column);
      break;
    }
  };

  return {
    responsiveTableSettings,
    responsiveTableRows,
    responsiveCollapsibleTableRows,
    hasVisibleColumns,
    removeCollapsibleColumn,
    addCollapsibleColumn,
  };
};
