export const CARGO_BOOKING_STATUS = {
  APPROVED: 'approved',
  VESSEL_NOMINATED: 'vessel nominated',
  CANCELLED: 'cancelled',
  UNDER_DISCUSSION: 'under discussion',
  DECLARED: 'declared',
  PENDING_G2O_REVIEW: 'pending_g2o_review',
  PENDING_CUSTOMER_REVIEW: 'pending_customer_review',
  NOMINATED: 'nominated',
} as const;

export const MESSAGE_TYPE = {
  NEW: 'new_nomination',
  APPROVED: 'approved_nomination',
  UPDATED: 'updated_nomination',
  TEXT: 'text',
  DECLARATION_UPDATED: 'updated_declaration',
  DECLARATION_APPROVED: 'approved_declaration',
} as const;

export const CARGO_BOOKING_TYPE = {
  BINDING: 'binding',
  FIXED: 'fixed',
  EXPECTED: 'expected',
} as const;

export const ERROR_PRIORITIES = {
  missing: 1,
  does_not_exist: 2,
  start_date_after_end: 2,
  date_in_past: 2,
  date_beyond_limit: 2,
  quantity_mismatch: 2,
  trade_port_mismatch: 2,
  laycan_bigger_than: 2,
  laycan_smaller_than: 2,
  limited_vessel_selection: 2,
  cant_change: 3,
} as const;

export const ASCII_A = 65;

export const BOOKING_PANES = {
  DECLARATION_VIEW: 'declaration_view',
  DECLARATION_CREATE: 'declaration_create',
  NOMINATION_VIEW: 'nomination_view',
  NOMINATION_CREATE: 'nomination_create',
} as const;
