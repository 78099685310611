<script setup lang="ts" name="DataTableRow">
import BaseCollapsible from '~/components/BaseCollapsible.vue';
import Icon from '~/components/Icon.vue';

const {
  editable,
  isCollapsibleDisabled = false,
  isCollapsibleOpen = false,
  selected = false,
} = defineProps<{
  clickable?: boolean;
  selected?: boolean;
  collapsible?: boolean;
  isCollapsibleOpen?: boolean;
  isCollapsibleDisabled?: boolean;
  editable?: boolean;
}>();

const emit = defineEmits<{
  toggle: [];
  onRowClick: [];
}>();

const onToggle = (toggle: () => void) => {
  toggle();
  emit('toggle');
};

const handleRowClicked = (toggle: () => void) => {
  if (!isCollapsibleDisabled && !editable) {
    onToggle(toggle);
  }
};
</script>

<template>
  <div
    v-if="!collapsible"
    role="row"
    class="col-span-full grid grid-cols-subgrid"
    :class="{
      'group border-b border-charcoal-2 last:border-none hover:bg-charcoal-1':
        !selected,
      'border-2 border-ocean-4 bg-ocean-0': selected,
      'cursor-pointer': clickable,
    }"
    @click="emit('onRowClick')"
  >
    <slot />
  </div>

  <BaseCollapsible
    v-else
    class="col-span-full grid grid-cols-subgrid border-b border-charcoal-2 last:border-none"
    transitionClass="col-span-full grid grid-cols-subgrid"
    :openByDefault="isCollapsibleOpen && !isCollapsibleDisabled"
  >
    <template #trigger="{ toggle, isOpen }">
      <div
        data-testid="expandable-row"
        class="col-span-full grid grid-cols-subgrid"
        :class="{
          'border-2 border-ocean-4 bg-ocean-0': selected,
          'group border-b border-charcoal-2 last:border-none hover:bg-charcoal-1':
            !selected,
        }"
        @click="handleRowClicked(toggle)"
      >
        <button
          class="flex"
          type="button"
          :disabled="isCollapsibleDisabled"
          @click.stop="onToggle(toggle)"
        >
          <div
            class="sticky left-0 top-0 z-10 flex w-min shrink-0 grow-0 items-center gap-x-2 px-6 py-4"
          >
            <Icon
              :icon="isOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'"
              :class="{
                'text-charcoal-4': isCollapsibleDisabled,
                'text-primary-8': !isCollapsibleDisabled,
              }"
            />
          </div>
        </button>

        <div
          role="row"
          class="col-[2_/_-1] grid grid-cols-subgrid"
          :class="{
            'cursor-pointer':
              collapsible && !isCollapsibleDisabled && !editable,
          }"
        >
          <slot :isOpen="isOpen" />
        </div>
      </div>
    </template>

    <slot name="collapsible" />
  </BaseCollapsible>
</template>
