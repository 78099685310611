<script lang="ts" setup name="UserListActiveUserInfo">
import { computed } from 'vue';

import { getFullName } from '~/features/useUtilities';

import Avatar from '~/components/Avatar.vue';

const {
  firstName = null,
  lastName = null,
  organisations,
  hasAllOrganisations = false,
  isInternal = false,
} = defineProps<{
  firstName?: string;
  lastName?: string;
  organisations: string[];
  hasAllOrganisations?: boolean;
  isInternal?: boolean;
}>();

const fullName = computed(() => getFullName(firstName, lastName));

const organisationNames = computed(() => {
  if (hasAllOrganisations) return 'All Organisations';

  const organisationsLength = organisations.length;

  if (!organisationsLength) return 'No Organisations';

  const [organisation] = organisations;

  return organisationsLength > 1
    ? `${organisation} (+${organisationsLength - 1})`
    : organisation;
});
</script>

<template>
  <div class="flex">
    <Avatar size="lg" :name="fullName" />
    <div class="flex flex-col justify-evenly pl-4">
      <p class="text-body-1">{{ fullName }}</p>
      <p v-if="!isInternal" class="text-body-2 text-charcoal-6">
        {{ organisationNames }}
      </p>
    </div>
  </div>
</template>
